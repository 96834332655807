<template>
  <LayoutThumbnailComponent
    :class="$style.root"
    :clusterBackgroundColor="clusterBackgroundColor"
    :image="coverImage"
    :includeDot="includeDot"
    :linkType="linkType"
    :localizedData="localizedData"
    linkName="venues-slug"
  >
    <template #tags>
      <SharedLinkToClusterComponent
        v-if="cluster?.titles && includeCluster"
        :data="cluster"
        data-narrow
        linkType="dynamic"
        sizeOption="tiny"
      />
    </template>

    <LayoutPillComponent
      v-if="includeClusterAsText && clusterTitle"
      :class="$style.clusterTitle"
      sizeOption="tiny"
      componentType="div"
      :style="{
        '--color': clusterBackgroundColor
      }"
    >
      {{ clusterTitle }}
    </LayoutPillComponent>

    <div
      :class="[$style.title, titleFontSize]"
      >
      {{ title }}
    </div>

    <div
    :class="$style.cityTitle"
    v-if="cityTitle"
    >
    {{ cityTitle }}
  </div>
  </LayoutThumbnailComponent>
</template>

<script setup>
const props = defineProps({
  data: Object,
  withRoundedBorder: {
    type: Boolean,
    default: true
  },
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  },
  includeCluster: {
    type: Boolean,
    default: true
  },
  includeDot: {
    type: Boolean,
    default: true
  },
  titleFontSize: {
    type: String,
    default: 'font-size-small'
  },
  includeClusterAsText: {
    type: Boolean,
    default: false
  }
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const cityTitle = computed(() => i18nGetLocalizedTitle(localizedData?.value?.city_entry?.data?.attributes))

const coverImage = computed(() => localizedData.value?.cover_image?.data?.attributes)

const cluster = computed(() => {
  const item = i18nGetLocalizedData(localizedData.value?.city_entry?.data?.attributes)
  return item?.cluster_entry?.data?.attributes
})

const clusterTitle = computed(() => i18nGetLocalizedTitle(cluster?.value))

const clusterBackgroundColor = computed(() => {
  return cluster?.value?.background_color
})
</script>

<style module>
.root {
  --layout--thumbnail--aspect-ratio: 1;
}

.cityTitle {
  composes: font-size-x-small from global;
  color: var(--color--blue);
}

.root:not(:has(.clusterTitle)) .title {
  margin-top: var(--unit--spacer);
}

.root:has(.clusterTitle) .title {
  margin-top: calc(var(--unit--spacer) / 2);
}

.clusterTitle {
  display: inline-block;
  pointer-events: none;
  --pill--border-color: transparent;
  --pill--background-color: var(--color);
  margin-top: var(--unit--spacer);
  display: inline-block;
}
</style>
